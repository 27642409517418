import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountInfoInitialState, AccountInfoTypes } from './account.state.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

const initialState: AccountInfoTypes = AccountInfoInitialState;

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<Partial<IUserPortal>>) => {
      state.account = action.payload;
      state.selectedGroup = String(action.payload.pvActiveGroupID);
      const userAccountsInfo = action.payload;
      const activeCurrentGroup =
        userAccountsInfo?.currentGroups &&
        userAccountsInfo.currentGroups.length &&
        userAccountsInfo.currentGroups.find(
          (group) => group.i === userAccountsInfo.pvActiveGroupID
        );
      state.selectedGroupName =
        activeCurrentGroup && activeCurrentGroup.n ? activeCurrentGroup.n : null;
    },
  },
});

export default accountSlice.reducer;

import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import AuthPage from '@/auth';
import { MainLayout } from 'components/layout';

// *: LOADER FUNCTIONS & CONTAINERS
import SelfRegisterLoaderContainer, {
  loaderSelfRegister,
} from 'pages/SelfRegister/SelfRegister.loader.tsx';

// *: COMPONENTS PAGE
const AdminPage = lazy(() => import('pages/AdminPage'));
const LoginPage = lazy(() => import('pages/Login/Login.container.tsx'));
const StationOnePage = lazy(() => import('pages/StationOne/StationOne.container.tsx'));
const StationTwoPage = lazy(() => import('pages/StationTwo/StationTwo.container.tsx'));
const StationThreePage = lazy(() => import('pages/StationThree'));
const StationFourPage = lazy(() => import('pages/StationFour'));
const StationFivePage = lazy(() => import('pages/StationFive'));
const SelfRegisterPage = lazy(() => import('pages/SelfRegister/SelfRegister.container.tsx'));

const JSXRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<AuthPage />}>
      <Route
        path='login'
        element={
          <Suspense fallback={<LinearProgress />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route path='admin' element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LinearProgress />}>
              <AdminPage newEntry={false} />
            </Suspense>
          }
        />
        <Route
          path='new'
          element={
            <Suspense fallback={<LinearProgress />}>
              <AdminPage newEntry={true} />
            </Suspense>
          }
        />
      </Route>
      <Route
        path='station1'
        element={
          <Suspense fallback={<LinearProgress />}>
            <StationOnePage />
          </Suspense>
        }
      />
      <Route
        path='station2'
        element={
          <Suspense fallback={<LinearProgress />}>
            <StationTwoPage />
          </Suspense>
        }
      />
      <Route
        path='station3'
        element={
          <Suspense fallback={<LinearProgress />}>
            <StationThreePage />
          </Suspense>
        }
      />
      <Route
        path='station4'
        element={
          <Suspense fallback={<LinearProgress />}>
            <StationFourPage />
          </Suspense>
        }
      />
      <Route
        path='station5'
        element={
          <Suspense fallback={<LinearProgress />}>
            <StationFivePage />
          </Suspense>
        }
      />
      <Route
        path='self-register'
        element={
          <Suspense fallback={<LinearProgress />}>
            <SelfRegisterPage />
          </Suspense>
        }
      />
      <Route
        path='self-register/:id/:receptionId'
        loader={loaderSelfRegister}
        element={<SelfRegisterLoaderContainer />}
      />
    </Route>
  )
);

export default JSXRoutes;

import { StationInitialState, StationStateTypes } from './station1.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateStationOne } from 'store/feature/station1/station1.thunk.ts';
import { QueryState } from '@/common/enum';
import { IStationOneAvailablePosts } from 'interfaces/admin.interface.ts';
import { IReceptionExtended } from 'pages/Login/hook/useLoginForm.ts';

const initialState: StationStateTypes = StationInitialState;

export const station1Slice = createSlice({
  name: 'station1',
  initialState,
  reducers: {
    updateSelectedRC: (state, action: PayloadAction<IReceptionExtended>) => {
      state.selectedReceptionCenter = action.payload;
    },
    updateSelectedPostSetting: (state, action: PayloadAction<IStationOneAvailablePosts>) => {
      state.selectedPostSetting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateStationOne.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(updateStationOne.fulfilled, (state) => {
        state.queryState = QueryState.UPDATED;
      })
      .addCase(updateStationOne.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default station1Slice.reducer;

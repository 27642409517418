import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PalmettoSideMenu from '@zawarski/palmetto-react-side-menu';
import { useAppSelector } from 'store/hooks.ts';
import { accountSelector } from 'store/feature/accountInfo/account.selector.ts';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/SettingsApplications';
import StationIcon from '@mui/icons-material/TransferWithinAStation';

const appTitle = import.meta.env.VITE_APP_TITLE ?? 'Welcome to DECON';

// type DrawerMenuViewProps = {
//   callback?: (value: boolean) => void;
// };

const DrawerMenuView: React.FC = () => {
  const navigate = useNavigate();
  const accountInfo = useAppSelector(accountSelector);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [activePath, setActivePath] = useState<string>('/admin');

  const userFullName = useMemo(() => {
    return `${accountInfo?.pvPersonGivenName} ${accountInfo?.pvPersonSurName}`;
  }, [accountInfo]);

  const defaultMenuItems = {
    menu: [
      {
        key: 'admin',
        name: 'Admin',
        path: '/admin',
        startIcon: <SettingsIcon />,
      },
      {
        key: 'station1',
        name: 'Station 1',
        path: '/station1',
        startIcon: <StationIcon />,
      },
      {
        key: 'station2',
        name: 'Station 2',
        path: '/station2',
        startIcon: <StationIcon />,
      },
      {
        key: 'station3',
        name: 'Station 3',
        path: '/station3',
        startIcon: <StationIcon />,
      },
      {
        key: 'station4',
        name: 'Station 4',
        path: '/station4',
        startIcon: <StationIcon />,
      },
      {
        key: 'station5',
        name: 'Station 5',
        path: '/station5',
        startIcon: <StationIcon />,
      },
    ],
    section: [
      {
        key: 'user', // this is the unique key identifier
        name: userFullName, // this is the name that will be displayed in the menu
        path: '/settings', // this is the path to the page
        startIcon: <PersonIcon />,
      },
      {
        key: 'logout',
        name: 'Log out',
        path: '/logout',
        endIcon: <LogoutIcon sx={{ color: '#eb021d' }} />,
      },
    ],
    version: {
      name: 'Version 1.0.0', // Define the version number
      color: '#999', // Define the default color
    },
  };

  useEffect(() => {
    const handleScroll = () => setIsDrawerOpen((prevState) => !prevState);
    window.addEventListener('toggle-sidebar', handleScroll); // Listen for the event
    return () => window.removeEventListener('toggle-sidebar', handleScroll);
  }, []);

  // const toggleDrawer = () => {
  //   setIsDrawerOpen((prevState) => !prevState);
  // };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  };

  const handleMenuClick = (link: string) => {
    setActivePath(link);
    if (link === '/logout') return handleLogout();
    if (link === '/settings') {
      return null;
    }
    if (link === '/contact') {
      return null;
    }
    navigate(link);
    setIsDrawerOpen(false);
  };

  return (
    <PalmettoSideMenu
      srcLogo={'/images/launcher.png'}
      menuItems={defaultMenuItems}
      isDrawerOpen={isDrawerOpen}
      activePath={activePath}
      activeSubMenu={false}
      programTitle={appTitle}
      onDrawerClose={() => setIsDrawerOpen(false)}
      onMenuClick={handleMenuClick}
      deactivateSubMenu={() => null}
    />
  );
};

export default DrawerMenuView;

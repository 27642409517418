import { StationInitialState, StationStateTypes } from './station5.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateStationFive } from 'store/feature/station5/station5.thunk.ts';
import { QueryState } from '@/common/enum';
import { IReceptionExtended } from 'pages/Login/hook/useLoginForm.ts';

const initialState: StationStateTypes = StationInitialState;

export const station5Slice = createSlice({
  name: 'station5',
  initialState,
  reducers: {
    updateSelectedRC: (state, action: PayloadAction<IReceptionExtended>) => {
      state.selectedReceptionCenter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateStationFive.pending, (state) => {
      state.queryState = QueryState.AWAIT;
      state.error = null;
    });
    builder.addCase(updateStationFive.fulfilled, (state) => {
      state.queryState = QueryState.UPDATED;
    });
    builder.addCase(updateStationFive.rejected, (state, action) => {
      state.queryState = QueryState.AWAIT;
      state.error = action.error.message ?? '';
    });
  },
});

export default station5Slice.reducer;

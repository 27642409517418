import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { IFetchStationResp } from 'interfaces/station.interface.ts';
import { IStationOneAvailablePosts } from 'interfaces/admin.interface.ts';
import { IReceptionExtended } from 'pages/Login/hook/useLoginForm.ts';

export type StationStateTypes = {
  queryState: QueryState;
  activeGroupId: Nullable<number>;
  selectedReceptionCenter: Nullable<IReceptionExtended>;
  selectedPostSetting: Nullable<IStationOneAvailablePosts>;
  error: Nullable<string>;
};

export const StationInitialState: StationStateTypes = {
  queryState: QueryState.UNDEFINED,
  activeGroupId: null,
  selectedReceptionCenter: null,
  selectedPostSetting: null,
  error: null,
};

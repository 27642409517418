import { Nullable } from 'interfaces/Nullable.ts';
import { IUserPortal, PalmettoGroups } from 'interfaces/UserAccountInfo.interface.ts';
import { QueryState, UserType } from '@/common/enum';

export type AccountInfoTypes = {
  appLoading: boolean;
  queryState: QueryState;
  account: Nullable<Partial<IUserPortal>>;
  error: Nullable<string>;
  selectedGroup: Nullable<string>;
  selectedGroupName: Nullable<string>;
  isUserGroupAdmin: Nullable<boolean>;
  userType: Nullable<UserType>;
  allGroups: Nullable<PalmettoGroups[]>;
};

export const AccountInfoInitialState: AccountInfoTypes = {
  appLoading: true,
  queryState: QueryState.UNDEFINED,
  error: null,
  account: null,
  selectedGroup: null,
  selectedGroupName: null,
  isUserGroupAdmin: null,
  userType: null,
  allGroups: null,
};
